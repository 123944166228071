import { Col, Input, Row, Button, Select, message, Space } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ConfirmationModal } from '../../shared/components/confirmation-modal';
import { setResourceName } from '../../shared/data/store';
import { Feature } from '../../shared/models/features';
import { translationNamespace } from '../constants/translation-resources';
import { DocumentsTable } from '../components/documents-table';
import { useHandleDocumentActionMutation } from '../data/mutations/document-mutations';
import {
  Document,
  DocumentId,
  disabledBatchAndDocumentStatus,
  approvedBatchAndDocumentStatus,
  FindDocumentsQueryParams,
} from '../models/document';
import { DocumentActions, DocumentAction } from '../constants/documents';
import { useTableOptions } from '../../shared/hooks/use-data-options';
import { useFindDocumentsQuery } from '../data/queries/document-queries';
import { isUserAuthorized } from '../../shared/utils/allowed-routes';
import { rejectDocumentRequiredRoute } from '../constants/required-routes';
import { useGetUserAllowedRoutesQuery } from '../../core/data/queries/core-queries';
import { userSelector } from '../../shared/data/store/selectors/auth-selectors';
import { useGetReasonsQuery } from '../data/queries/batch-queries';
import { languageReasonsRequestMap } from '../constants/translation-resources';

type DocumentsListViewProps = Pick<FindDocumentsQueryParams, 'batchName' | 'filter' | 'search' | 'wId'>;

const { TextArea } = Input;

export const DocumentsListView: FC<DocumentsListViewProps> = ({ batchName, filter, search, wId }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);
  const actionMutation = useHandleDocumentActionMutation();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<React.Key[]>([]);
  const [actionType, setActionType] = useState<DocumentAction>(null);
  const [textareaText, setTextareaText] = useState<string>();
  const [rejectionReason, setRejectionReason] = useState<string>(null);
  const { page, pageSize, handleTableChange } = useTableOptions<Document>();
  const { data, isLoading } = useFindDocumentsQuery({ batchName, page, pageSize, filter, search, wId });
  const { data: userAllowedRoutes } = useGetUserAllowedRoutesQuery();
  const { data: reasons, isLoading: areReasonsLoading } = useGetReasonsQuery(
    {
      groupId: currentUser?.groups[0]?.id,
      type: 'document',
      typeAction: DocumentActions.REJECTED,
      lang: languageReasonsRequestMap[currentLanguage],
    },
    actionType != null
  );

  const isAuthorizedToReject = isUserAuthorized(rejectDocumentRequiredRoute, userAllowedRoutes);

  const onFinishAction = () => {
    setSelectedDocumentIds([]);
    setRejectionReason(undefined);
    setActionType(null);
    setTextareaText('');
  };

  const onCancel = () => {
    setActionType(null);
    setRejectionReason(undefined);
    setTextareaText('');
  };

  const confirmAction = () => {
    actionMutation.mutate(
      {
        user: currentUser.username,
        msg: rejectionReason,
        comment: textareaText,
        documentsIds: selectedDocumentIds as DocumentId[],
        actionType,
      },
      {
        onSuccess: () => {
          message.success(
            t('reject.success', {
              resourceType: t('document'),
            })
          );
        },
        onSettled: () => onFinishAction(),
      }
    );
  };

  const rowSelection = {
    selectedRowKeys: selectedDocumentIds,
    onChange: setSelectedDocumentIds,
    getCheckboxProps: (doc: Document) => ({
      disabled:
        approvedBatchAndDocumentStatus.includes(doc.status) || disabledBatchAndDocumentStatus.includes(doc.status),
    }),
  };

  const applyStyles = (doc: Document) => {
    return disabledBatchAndDocumentStatus.includes(doc.status)
      ? { style: { opacity: 0.5, backgroundColor: '#7F7F7F' } }
      : approvedBatchAndDocumentStatus.includes(doc.status)
      ? { style: { opacity: 0.5, backgroundColor: '#6CA885' } }
      : {};
  };

  const renderRowClassName = row => {
    if (row.status === 'approved' || row.status === 'rejected' || row.status === 'processed') {
      return 'disabled-row';
    }

    return '';
  };

  const sharedButtonProps = {
    type: 'primary' as ButtonType,
    size: 'large' as SizeType,
    disabled: !(selectedDocumentIds.length > 0),
  };

  const resId = wId + batchName;

  useEffect(() => {
    dispatch(setResourceName(resId));
  }, [dispatch, resId]);

  return (
    <StyledRow>
      {isAuthorizedToReject && (
        <StyledSpace>
          <Button {...sharedButtonProps} onClick={() => setActionType(DocumentActions.REJECTED)}>
            {t('reject.button')}
          </Button>
        </StyledSpace>
      )}
      <Col style={{ marginTop: isLoading || data?.total === 0 ? '50px' : '0' }}>
        <StyledTable
          dataSource={data}
          loading={isLoading}
          rowSelection={rowSelection}
          pagination={{
            total: data?.total,
            current: page,
            pageSize,
            showSizeChanger: data?.total > 10,
            position: ['topRight'],
          }}
          onRow={doc => applyStyles(doc)}
          onChange={handleTableChange}
          rowClassName={renderRowClassName}
          scroll={{ y: 480 }}
        />
      </Col>

      <ConfirmationModal
        destroyOnClose
        open={actionType != null}
        title={t(`${Feature.SHARED}:areYouSure`)}
        content={t('reject.modalContent', {
          buttonTitle: t(`${Feature.SHARED}:confirm`),
          amount: `${selectedDocumentIds.length}`,
          resourceType: t('document'),
        })}
        onCancel={onCancel}
        onOk={confirmAction}
        okButtonProps={{ disabled: rejectionReason == null }}
        children={
          <>
            <p style={{ marginTop: '10px', width: '100%' }}>{t('reject.reason')}</p>
            <Select
              style={{ width: '100%' }}
              options={reasons}
              onSelect={setRejectionReason}
              loading={areReasonsLoading}
            />
            <TextArea
              style={{ marginTop: '10px' }}
              rows={4}
              placeholder={t('reject.comment')}
              onChange={event => setTextareaText(event.target.value)}
            />
          </>
        }
      />
    </StyledRow>
  );
};

export const marginButton = css`
  margin-left: 20px;
`;

const StyledTable = styled(DocumentsTable)`
  min-width: 1000px !important;

  && .disabled-row:hover {
    td {
      background-color: inherit;
    }
  }

  @media (min-width: 1801px) {
    max-width: calc(100vw - 290px);
  }
`;

const StyledSpace = styled(Space)`
  position: absolute;
  z-index: 1;

  .ant-space-item {
    height: 38px;
  }
`;

const StyledRow = styled(Row)`
  margin: 0 auto;
  min-height: 170px;
`;
