import { Button, Col, Form, FormInstance, Input, Row, Select, Spin } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { startOfDay, endOfDay } from 'date-fns';
import { css } from '@emotion/react';
import { GetFilterResponse } from '@pccr-ifc/pccr-ifc';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';
import { onlyNumbersRule } from '../../../shared/utils/form-rules';
import { DocumentFiltersType } from '../../constants/documents';
import { DocumentsFilters } from '../../models/batch';

interface BatchesTableFiltersFormProps {
  onApply?: (filters: DocumentsFilters) => void;
  form: FormInstance<DocumentsFilters>;
  filtersColumns: GetFilterResponse;
  areFiltersLoading: boolean;
}

const { Option } = Select;

export const BatchesTableFiltersForm: FC<BatchesTableFiltersFormProps> = ({
  onApply,
  form,
  filtersColumns,
  areFiltersLoading,
}) => {
  const { t } = useTranslation(translationNamespace);

  const defaultStartDate = startOfDay(new Date());
  const defaultEndDate = endOfDay(new Date());

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  function translate(col: string, val: string) {
    if (col.toLocaleLowerCase() === 'batchstatus') {
      const st = t(`${Feature.APPROVAL}:documentsTable.statuses.${val.toLowerCase()}`);
      return st;
    }
    return val;
  }

  const returnFilterForm = (columnName: string, type: DocumentFiltersType) => {
    if (Array.isArray(type)) {
      return (
        <Col span={9}>
          <Form.Item label={t(`batches.filters.${columnName}`)} name={columnName}>
            <Select size="large" mode="multiple">
              {type.map((value: string) => (
                <Option value={value} key={value}>
                  {translate(columnName, value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      );
    }

    switch (type) {
      case 'dateRange':
        return (
          <Col span={8}>
            <Form.Item label={t(`batches.filters.${columnName}`)} name={columnName}>
              <RangePicker
                showTime={{
                  format: 'HH:mm',
                  defaultValue: [defaultStartDate, defaultEndDate],
                }}
                css={fullWidthStyles}
              />
            </Form.Item>
          </Col>
        );
      case 'integerRange':
        return (
          <Col span={9} style={{ display: 'flex' }}>
            <Form.Item
              label={t(`batches.filters.${columnName}`)}
              name={`from_${columnName}`}
              rules={[onlyNumbersRule(t)]}
            >
              <Input addonBefore="from" css={fullWidthStyles} size="large" />
            </Form.Item>

            <Form.Item name={`to_${columnName}`} rules={[onlyNumbersRule(t)]} style={{ marginTop: '30px' }}>
              <Input addonBefore="to" css={fullWidthStyles} size="large" />
            </Form.Item>
          </Col>
        );
      default:
        return (
          <Col span={6}>
            <Form.Item label={t(`batches.filters.${columnName}`)} name={columnName}>
              <Select suffixIcon={null} css={fullWidthStyles} size="large" allowClear open={false} mode="tags" />
            </Form.Item>
          </Col>
        );
    }
  };

  return areFiltersLoading ? (
    <Spin />
  ) : (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        {Object.entries(filtersColumns?.filter.columns).map(([columnName, type]) => returnFilterForm(columnName, type))}
      </Row>

      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
  border-radius: 0px;
`;
